import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ScheduledNotificationDetailDto, DaysOfWeekEnum, ScheduledNotificationsClient, FilterDto, FilterItemDto, FilterTypeEnum, GridColumnDto, ColumnTypeEnum, ShipmentsClient, FiltersClient} from './../../core/services/api.service';
import { Component, OnInit, inject } from '@angular/core';
import { RequestService } from '../../core/services/request.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UserResolverService } from '../../core/services/user-resolver.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { DateTime } from 'luxon';
import { UserSettingsKeys } from '../../core/services/user-setting.service';
import { StaticOptions } from '../../core/constants';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'sn-notification-add-edit-modal',
    templateUrl: './notification-add-edit-modal.component.html',
    styleUrls: ['./notification-add-edit-modal.component.scss'],
    imports: [MatDialogModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, NgClass, MatButtonModule]
})
export class NotificationAddEditModalComponent implements OnInit {
  private _userResolverService = inject(UserResolverService);
  private _scheduledNotificationsClient = inject(ScheduledNotificationsClient);
  private _requestService = inject(RequestService);
  private _matDialogRef = inject<MatDialogRef<NotificationAddEditModalComponent>>(MatDialogRef);
  private _shipmentsClient = inject(ShipmentsClient);
  private _filtersClient = inject(FiltersClient);
  protected _matDialog = inject(MatDialog);
  data = inject<{
    id: number;
    filterData: {
        filter: FilterDto;
        columns: GridColumnDto[];
    };
}>(MAT_DIALOG_DATA);

  id: number | undefined;
  notificationTypes = StaticOptions.notificationTypes;
  notificationTypeId: string | undefined;
  scheduledNotification: ScheduledNotificationDetailDto = new ScheduledNotificationDetailDto();
  scheduleEnum = ScheduleEnum;
  schedule: ScheduleEnum = ScheduleEnum.daily;
  timeHour: string = '12';
  timeMinute: string = '00';
  timePeriod: string = "am";
  sunday: boolean = false;
  monday: boolean = false;
  tuesday: boolean = false;
  wednesday: boolean = false;
  thursday: boolean = false;
  friday: boolean = false;
  saturday: boolean = false;
  timeMinutes: string[] = this.createRange(60);
  timeHours: string[] = this.createRange(12, false);
  filter: FilterDto | undefined;
  columns: GridColumnDto[] | undefined;

  constructor() {
    const _userResolverService = this._userResolverService;
    const data = this.data;

    _userResolverService.userInfo.subscribe(x => this.scheduledNotification.userEmail = x.Email);
    if (data.id) {
      this.id = data.id;
    }
    if (data.filterData) {
      this.filter = data.filterData.filter;
      this.columns = data.filterData.columns ?? [];
      this.notificationTypes = this.notificationTypes.filter(x => x.Id == UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION);
    }
  }

  ngOnInit(): void {
    if (!this.id) {
      // set default values
      if (this.filter) {
        this.notificationTypes = this.notificationTypes.filter(x => x.Id == UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION);
      } else {
        this.notificationTypes = this.notificationTypes.filter(x => x.Id != UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION);
      }
      this.scheduledNotification.key = this.notificationTypes[0].Id;
      this.schedule = ScheduleEnum.daily;
      this.setScheduledNotificationModel();
    } else {
      this.getScheduledNotification();
    }
  }

  getShipmentColumns() {
    this._requestService.get(this._shipmentsClient.shipments_GetGridOptions(), (data: GridColumnDto[] | undefined) => {
      if (data) {
        this.columns = data;
      }
    }, "Shipment Columns");
  }

  onScheduleChange(e: MatSelectChange) {
    this.schedule = e.value;
    this.sunday = this.monday = this.tuesday = this.wednesday = this.thursday = this.friday = this.saturday = false;
    this.setSelectedDaysToSend();
  }

  timeHourChange(e: MatSelectChange) {
    this.timeHour = e.value;
  }

  timeMinuteChange(e: MatSelectChange) {
    this.timeMinute = e.value;
  }

  timePeriodChange(e: MatSelectChange) {
    this.timePeriod = e.value;
  }

  isModelValid() {
    if (!this.scheduledNotification.name) {
      return false;
    }

    if (!this.scheduledNotification.key) {
      return false;
    }

    return true;
  }

  confirmDeleteScheduledNotification() {
    const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want to delete this scheduled notificaiton?",
        cancelButtonText: "Cancel",
        displayMessageAsHTML: true,
        okButtonText: "Delete"
      },
      disableClose: true
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteScheduledNotification();
      }
    });
  }

  saveScheduledNotification() {
    if (!this.isModelValid()) {
      return;
    }

    this.setScheduledNotificationModel();
    if (this.scheduledNotification.id) {
      this.editScheduledNotificaiton();
    } else {
      this.createScheduledNotificaiton();
    }
  }

  private createRange(number: number, isZeroBased: boolean = true): string[] {
    return new Array(number).fill(0)
      .map((n, index) => (isZeroBased ? index : index + 1).toString().length < 2 ? '0' + (isZeroBased ? index : index + 1) : (isZeroBased ? index : index + 1) + '');
  }

  private deleteScheduledNotification() {
    var id = this.scheduledNotification.id ? this.scheduledNotification.id : 0;
    if (id == 0) {
      return;
    }

    this._requestService.delete(this._scheduledNotificationsClient.scheduledNotifications_Delete(id),() => {
      this._matDialogRef.close(true);
    }, "Scheduled Notification");
  }

  private createScheduledNotificaiton() {
    this._requestService.save(this._scheduledNotificationsClient.scheduledNotifications_Create(this.scheduledNotification),() => {
      this._matDialogRef.close(true);
    }, "Scheduled Notification");
  }

  private editScheduledNotificaiton() {
    var id = this.scheduledNotification.id ? this.scheduledNotification.id : 0;
    if (id == 0) {
      return;
    }
    this._requestService.save(this._scheduledNotificationsClient.scheduledNotifications_Update(id, this.scheduledNotification),() => {
      this._matDialogRef.close(true);
    }, "Scheduled Notification");
  }

  private setScheduledNotificationModel() {
    this.setSelectedDaysToSend();
    this.setScheduledNotificationDisplayTime();
    if (this.isFilterNotif()) {
      this.scheduledNotification.dataJSON = this.filter.id.toString();
    }
  }
  
  private setScheduledNotificationDisplayTime() {
    const timeHour24 = this.to24HourFormat(this.timeHour, this.timePeriod);
    let date = DateTime.now();
    date = date.set({ hour: parseInt(timeHour24), minute: parseInt(this.timeMinute) });
    this.scheduledNotification.displayTime = date.setZone(DateTime.utc().zoneName).toFormat("t");
    if (this.scheduledNotification.displayTime.length == 7) {
      this.scheduledNotification.displayTime = "0" + this.scheduledNotification.displayTime;
    }
  }

  private setSelectedDaysToSend() {
    this.scheduledNotification.selectedDaysToSend = [];

    if (this.schedule == ScheduleEnum.daily) {
      this.sunday = this.monday = this.tuesday = this.wednesday = this.thursday = this.friday = this.saturday = true;
    }

    if (this.sunday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Sunday);
    }

    if (this.monday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Monday);
    }

    if (this.tuesday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Tuesday);
    }

    if (this.wednesday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Wednesday);
    }

    if (this.thursday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Thursday);
    }

    if (this.friday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Friday);
    }

    if (this.saturday) {
      this.scheduledNotification.selectedDaysToSend.push(DaysOfWeekEnum.Saturday);
    }
  }

  private getScheduledNotification() {
    if (!this.id) {
      return;
    }

    this._requestService.get(this._scheduledNotificationsClient.scheduledNotifications_GetDetailsById(this.id), (data: ScheduledNotificationDetailDto | undefined) => {
      if (data) {
        this.mapToFormModel(data);
        if (this.isFilterNotif() && (!this.columns || !this.columns?.length)) {
          this.getShipmentColumns();
        }
      }
    }, "Scheduled Notification");
  }

  private mapToFormModel(data: ScheduledNotificationDetailDto) {
    this.scheduledNotification = data;
    this.scheduledNotification.time = undefined;
    this.notificationTypeId = data.key;

    if (data.displayTime && data.displayTime?.length > 0 ) {
      const period = data.displayTime.split(" ")[1];
      const minute = data.displayTime.split(" ")[0].split(":")[1];
      const hour24 = this.to24HourFormat(data.displayTime.split(":")[0], period);

      let date = DateTime.utc();
      date = date.set({ hour: parseInt(hour24), minute: parseInt(minute) });
      let userDisplayTime = date.toLocal().toLocaleString(DateTime.TIME_SIMPLE);
      if (userDisplayTime.length == 7) {
        userDisplayTime = "0" + userDisplayTime;
      }

      this.timeHour = userDisplayTime.split(":")[0], period
      this.timeMinute = userDisplayTime.split(" ")[0].split(":")[1];
      this.timePeriod = userDisplayTime.split(" ")[1].toLowerCase();
    }

    if (data.selectedDaysToSend) {
      this.schedule = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.All) ? ScheduleEnum.daily : ScheduleEnum.specificDays;
      this.sunday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Sunday);
      this.monday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Monday);
      this.tuesday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Tuesday);
      this.wednesday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Wednesday);
      this.thursday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Thursday);
      this.friday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Friday);
      this.saturday = data.selectedDaysToSend.some(s => s == DaysOfWeekEnum.Saturday);
    }

    if (data.key == UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION) {
      this.notificationTypes = this.notificationTypes.filter(x => x.Id == UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION);
      const parsedId = parseInt(data.dataJSON);
      if (!isNaN(parsedId)) {
        this._requestService.get(this._filtersClient.filters_Get(parseInt(data.dataJSON)), (innerData: FilterDto | undefined) => {
          if (innerData) {
            this.filter = innerData;
          }
        }, "Shipment Filter");
      }
    } else {
      this.notificationTypes = this.notificationTypes.filter(x => x.Id != UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION);
    }
  }

  private to24HourFormat(hour: string, period: string): string {
    if (period.toLowerCase() == "pm") {
      hour = parseInt(hour) + 12 + "";
    }

    if (period.toLowerCase() == "am" && hour == "12") {
      hour = "00";
    }

    if (hour.length == 1) {
      hour = "0" + hour;
    }

    return hour;
  }

  isFilterNotif(): boolean {
    return this.scheduledNotification.key == UserSettingsKeys.SENDFILTEREDSHIPMENTNOTIFICATION;
  }

  hasNoDateFilter(): boolean {
    return !this.filter?.filterItems.some(x => x.columnType == ColumnTypeEnum.Date);
  }
}

enum ScheduleEnum {
  daily = 0,
  specificDays = 1
}
